import { graphql } from 'gatsby';
import 'normalize.css';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import AboutUs from '../components/AboutUs';
import BanksGrid from '../components/BanksGrid';
import BottomBar from '../components/BottomBar';
import Hero from '../components/Hero';
import Layout from '../components/Layout';
import Numbers from '../components/Numbers';
import Partnership from '../components/Partnership';
import SEO from '../components/seo';
import Testimonials from '../components/Testimonials';
import ThinkDigitalGrid from '../components/ThinkDigitalGrid';
import MediaLanding from '../components/MediaLanding';
import '../styles/global.scss';

const IndexPage = ({ data }) => {
  const bannerImg = data.ogImg.ogimage[3].file.url;
  return (
    <>
      <Layout>
        <SEO
          title='Home'
          description={data.contentfulHomepage.homepageHero[0]}
          bannerImage={bannerImg}
          pathname=''
        />
        <Helmet>
          <link
            rel='stylesheet'
            href='https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.0.0/animate.min.css'
          />
          <meta name="google-site-verification" content="AkERByfFfNOjTpxNOZ8Qm-7hwGS8oTzueTkrOC_I_RE" />
        </Helmet>
        <Hero
          title={data.contentfulHomepage.homepageHero[0]}
          subtitle={data.contentfulHomepage.homepageHero[1]}
          bgImage={data.contentfulHomepage.heroImageHomepage.file.url}
          isHomePage={true}
          isProducts={true}
        />
        <BanksGrid BanksGrid={data.contentfulHomepage.banksGrid} />
        <Partnership
          heading={data.contentfulPartners.microsoftTitle}
          Partnership={data.contentfulPartners.partnerships}
        />
        <ThinkDigitalGrid productsData={data.allContentfulProduct.nodes} />
        <Testimonials />
        <MediaLanding />
        {/* Removed if no Animation */}
        {/* <Categories
        bg={data.contentfulHomepage.categoryImages[0].file.url}
        categoryImages={data.contentfulHomepage.categoryImages}
        bankIcon={data.contentfulHomepage.bankIcon.file.url}
        nucleiN={data.contentfulHomepage.nucleiN.file.url}
        staticCategories={data.contentfulHomepage.staticCategories.file.url}
      /> */}
        <Numbers
          noOfBanks={data.contentfulHomepage.noOfBanks}
          noOfCategories={data.contentfulHomepage.noOfCategories}
          noOfPartners={data.contentfulHomepage.noOfPartners}
          noOfTransactions={data.contentfulHomepage.noOfTransactions}
        />
        {/* <CaseStudy /> */}
        <AboutUs
          images={data.contentfulHomepage.aboutUsImage}
          content={data.contentfulHomepage.aboutUsContent.aboutUsContent}
        />
        <BottomBar />
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    allContentfulAsset: PropTypes.shape({
      nodes: PropTypes.any,
    }),
    contentfulAsset: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.any,
      }),
    }),
    contentfulFooter: PropTypes.shape({
      altTextBelowLogo: PropTypes.any,
      bethebank: PropTypes.any,
      bottomImage: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.any,
        }),
      }),
      footerLogo: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.any,
        }),
      }),
    }),
    contentfulFooterExploreJsonNode: PropTypes.any,
    contentfulFooterQuickLinksJsonNode: PropTypes.any,
    contentfulHomepage: PropTypes.shape({
      aboutUsContent: PropTypes.shape({
        aboutUsContent: PropTypes.any,
      }),
      aboutUsImage: PropTypes.any,
      bankIcon: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.any,
        }),
      }),
      banksGrid: PropTypes.any,
      categoryImages: PropTypes.any,
      headingsProducts: PropTypes.any,
      heroImageHomepage: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.any,
        }),
      }),
      homepageHero: PropTypes.any,
      nucleiN: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.any,
        }),
      }),
      numbers: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.any,
        }),
      }),
      productsDetails: PropTypes.any,
      staticCategories: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.any,
        }),
      }),
      testimonialImage: PropTypes.any,
      testimonials: PropTypes.any,
      thinkDigitalImages: PropTypes.any,
    }),
    contentfulNavbar: PropTypes.shape({
      about: PropTypes.any,
      products: PropTypes.any,
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.any,
      }),
    }),
  }),
};

export const query = graphql`
  {
    contentfulPartners {
      microsoftTitle
      partnershipTitle
      clientLogos {
        file {
          url
        }
      }
      partnerships {
        file {
          url
          fileName
        }
      }
    }
    ogImg: contentfulOpenGraph {
      ogimage {
        file {
          url
          fileName
        }
      }
    }
    contentfulHomepage(children: {}) {
      noOfBanks
      noOfCategories
      noOfPartners
      noOfTransactions
      homepageHero
      heroImageHomepage {
        file {
          url
        }
      }
      merchantMarketPlaceData {
        merchantMarketPlaceData
      }
      merchantMarketplaceImage {
        file {
          url
        }
      }
      digitalBankingData {
        digitalBankingData
      }
      digitalBankingImage {
        file {
          url
        }
      }
      banksGrid {
        file {
          url
        }
      }
      thinkDigitalImages {
        file {
          url
        }
      }

      aboutUsContent {
        aboutUsContent
      }
      aboutUsImage {
        file {
          url
        }
      }
      categoryImages {
        file {
          url
        }
      }
      bankIcon {
        file {
          url
        }
      }
      nucleiN {
        file {
          url
        }
      }
      staticCategories {
        file {
          url
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
    allContentfulAsset(filter: { title: { eq: "breaker" } }) {
      nodes {
        file {
          url
        }
      }
    }
    allContentfulProduct(sort: { fields: [priority], order: ASC }) {
      nodes {
        homepageData {
          homepageData
        }
        homepageImage {
          file {
            url
          }
        }
        priority
        slug
        name
      }
    }
  }
`;
export default IndexPage;
