import PropTypes from 'prop-types';
import React from 'react';
import bg from '../images/numbers.svg';

import '../styles/global.scss';
import '../styles/animation/animationCircle.scss';
const Numbers = ({
  noOfBanks,
  noOfCategories,
  noOfPartners,
  noOfTransactions,
}) => {
  const [data, setData] = React.useState('Banks');
  const [numbers, setNumbers] = React.useState(16);
  React.useEffect(() => {
    let array = [noOfBanks, noOfCategories, noOfTransactions, noOfPartners],
      numbersSubtext = [
        'Banks',
        'Categories',
        'Million Transactions per month',
        'Partners',
      ],
      intervalDurationMs = 3000,
      currentIndex = 0,
      maxNumTimes = -1,
      numTimesRan = 0;

    let interval = setInterval(function () {
      if (maxNumTimes !== 0) {
        setNumbers(array[currentIndex]);
        setData(numbersSubtext[currentIndex]);
        currentIndex++;
        if (currentIndex > array.length - 1) {
          if (maxNumTimes === -1) {
            currentIndex = 0;
          } else {
            numTimesRan++;
            if (numTimesRan === maxNumTimes) {
              clearInterval(interval);
            } else {
              currentIndex = 0;
            }
          }
        }
      } else {
        clearInterval(interval);
      }
    }, intervalDurationMs);
  }, [noOfBanks, noOfCategories, noOfPartners, noOfTransactions, setNumbers]);
  return (
    <section className='section'>
      <div className='columns'>
        <div
          className='column is-full is-centered'
          style={{
            textAlign: 'center',
            backgroundImage: `url(${bg}) bottom no-repeat`,
          }}
        >
          <div className='container'>
            <svg
              className='loader-numbers'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 340 340'
            >
              <circle cx='220' cy='5' r='0.5' stroke='#009eff' />
              <circle cx='230' cy='20' r='0.5' stroke='#009eff' />
              <circle cx='130' cy='10' r='0.5' stroke='#b45fff' />
              <circle cx='320' cy='100' r='0.5' stroke='#009eff' />
              <circle cx='300' cy='100' r='0.5' stroke='#b45fff' />
              <circle cx='310' cy='120' r='0.5' stroke='#808080' />
              <circle cx='230' cy='20' r='0.5' stroke='#009eff' />
              <circle cx='10' cy='120' r='0.5' stroke='#808080' />
              <circle cx='80' cy='20' r='0.5' stroke='#009eff' />
              <circle cx='80' cy='300' r='0.5' stroke='#b45fff' />
              <circle cx='10?0' cy='310' r='0.5' stroke='#009eff' />
              <circle cx='300' cy='100' r='0.5' stroke='#b45fff' />
              <circle cx='130' cy='20' r='0.5' stroke='#808080' />
              <circle cx='10' cy='120' r='0.5' stroke='#808080' />
              <circle cx='280' cy='280' r='0.5' stroke='#009eff' />
              <circle cx='300' cy='260' r='0.5' stroke='#b45fff' />
              <circle cx='100' cy='310' r='0.5' stroke='#009eff' />
              <circle cx='300' cy='100' r='0.5' stroke='#b45fff' />
              <circle cx='130' cy='20' r='0.5' stroke='#808080' />
              <circle cx='170' cy='170' r='165' stroke='#a9a9a9' />
              <circle cx='170' cy='170' r='160' stroke='#a9a9a9' />
              <circle cx='170' cy='170' r='155' stroke='#878787' />
              <circle cx='170' cy='170' r='150' stroke='#878787' />
              <circle cx='170' cy='170' r='140' stroke='#009eff' />
              <foreignObject x='21%' y='30%' width='200px' height='250px'>
                <div className='percent-text'>
                  <div className='text'>{numbers}</div>
                </div>
                <p
                  style={{
                    maxWidth: '320px',
                    fontSize: '14px',
                    color: '#878787',
                    fontFamily: 'Muli',
                  }}
                >
                  {data}
                </p>
              </foreignObject>
            </svg>
          </div>
        </div>{' '}
      </div>
    </section>
  );
};

Numbers.propTypes = {
  image: PropTypes.any,
};

export default Numbers;
