import PropTypes from 'prop-types';
import React from 'react';
import divider from '../images/divider-colored.svg';
import bg from '../images/AboutU.png';
import '../styles/global.scss';
import { Link } from 'gatsby';

const AboutUs = ({ images, content }) => {
  return (
    <section
      className='section '
      style={{
        marginLeft: 0,
        paddingLeft: 0,
        paddingRight: 0,
        marginRight: 0,
        // maxHeight: '100vh',
      }}
    >
      <div
        className='columns '
        style={{
          backgroundImage: 'linear-gradient(to top, #ffffff 1%, #dff3ff)',
          maxWidth: '100%',
        }}
      >
        <div className='column is-half is-mobile is-full  is-hidden-mobile'>
          <img
            src={bg}
            style={{ height: '725px', maxHeight: '100vh' }}
            alt='About us'
            loading='lazy'
          />
        </div>
        <div className='column aboutUS-col' style={{ padding: '60px 0 0 0' }}>
          <img src={divider} alt='divider' className='divider' />
          <p className='about-us-heading'>About Us</p>
          <br />
          <div>
            {content.split('\n').map((item, i) => (
              <p className='about-us-content' key={i}>
                {item}
              </p>
            ))}
          </div>
          <Link to='/about'>
            <div className='button is-hidden-mobile '>
              <span className='button-text'>Know More</span>
            </div>
          </Link>
          <Link to='/about'>
            <div
              style={{ marginLeft: '15px' }}
              className='button is-hidden-desktop is-hidden-tablet'
            >
              <span className='button-text'>Know More</span>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

AboutUs.propTypes = {
  content: PropTypes.shape({
    split: PropTypes.func,
  }),
  images: PropTypes.any,
};

export default AboutUs;
