import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'gatsby';
import Zoom from 'react-reveal/Zoom';

import check from '../images/checked.svg';
import divider from '../images/divider-colored.svg';

import '../styles/global.scss';

const ThinkDigitalGrid = ({ productsData }) => {
  return (
    <section
      style={{
        paddingTop: '120px',
        marginTop: '0',
        marginBottom: '3rem',
      }}
    >
      <div className='columns is-8' style={{ maxWidth: '100%' }}>
        <div className='column is-full'>
          <center>
            <img src={divider} alt='divider' />
            <p className='SectionHeading' style={{ padding: 0, margin: 0 }}>
              Think Digital, Think Nuclei
            </p>
            <p className='SectionSubHeading'>
              Customer’s expectations with their Banks have grown significantly
            </p>
          </center>
        </div>
      </div>

      {Object.keys(productsData).map(i => {
        return (
          <>
            {/* Image on right for even rows and Image on left for odd rows */}
            {i % 2 === 0 ? (
              <div className='columns' style={{ maxWidth: '100%' }}>
                <Zoom left>
                  <div className='column left-col-prod'>
                    <div className='title-mp'>
                      <div>{productsData[i].name}</div>
                    </div>
                    <div className='description-home'>
                      {productsData[i]?.homepageData?.homepageData
                        .split('\n')
                        .map((item, i) => (
                          <span style={{ display: 'flex' }}>
                            <img
                              src={check}
                              width='20'
                              alt='check'
                              style={{
                                marginRight: '15px',
                                height: '24px',
                                marginTop: '10px',
                              }}
                            />
                            <div
                              key={i}
                              className='description-home'
                              style={{ marginBottom: '20px' }}
                            >
                              {' '}
                              {item}
                            </div>
                          </span>
                        ))}
                      <Link to={`/product/${productsData[i].slug}`}>
                        <div
                          className='button is-hidden-mobile '
                          style={{ marginTop: '25px', marginLeft: '35%' }}
                        >
                          <span className='button-text'>Know More</span>
                        </div>
                      </Link>
                      <center>
                        <Link to={`/product/${productsData[i].slug}`}>
                          <div
                            className='button is-hidden-tablet is-hidden-desktop '
                            style={{ marginTop: '25px' }}
                          >
                            <span className='button-text'>Know More</span>
                          </div>
                        </Link>
                      </center>
                    </div>
                  </div>
                </Zoom>
                <div className='column'>
                  <center>
                    <Zoom right>
                      <img
                        src={productsData[i]?.homepageImage?.file?.url}
                        className='phone-home'
                        alt={'image'}
                      />
                    </Zoom>
                  </center>
                </div>
              </div>
            ) : null}
            {/* Image on right for even rows and Image on left for odd rows */}
            {i % 2 !== 0 ? (
              <div
                className='columns is-center-odd'
                style={{ maxWidth: '100%' }}
              >
                <Zoom left>
                  <div className='column'>
                    <center>
                      <Zoom right>
                        <img
                          src={productsData[i]?.homepageImage?.file?.url}
                          className='phone-home'
                          alt={'image'}
                        />
                      </Zoom>
                    </center>
                  </div>
                  <div className='column left-col-prod'>
                    <div className='title-mp'>
                      <div>{productsData[i]?.name}</div>
                    </div>
                    <div className='description-home description-right'>
                      {productsData[i]?.homepageData?.homepageData
                        .split('\n')
                        .map((item, i) => (
                          <span style={{ display: 'flex' }}>
                            <img
                              src={check}
                              width='20'
                              alt='check'
                              style={{
                                marginRight: '15px',
                                height: '24px',
                                marginTop: '10px',
                              }}
                            />
                            <div
                              key={i}
                              className='description-home'
                              style={{ marginBottom: '20px' }}
                            >
                              {' '}
                              {item}
                            </div>
                          </span>
                        ))}
                      <Link to={`/product/${productsData[i].slug}`}>
                        <div
                          className='button is-hidden-mobile '
                          style={{ marginTop: '25px', marginLeft: '35%' }}
                        >
                          <span className='button-text'>Know More</span>
                        </div>
                      </Link>
                      <center>
                        <Link to={`/product/${productsData[i].slug}`}>
                          <div
                            className='button is-hidden-tablet is-hidden-desktop '
                            style={{ marginTop: '25px' }}
                          >
                            <span className='button-text'>Know More</span>
                          </div>
                        </Link>
                      </center>
                    </div>
                  </div>
                </Zoom>
              </div>
            ) : null}
          </>
        );
      })}
    </section>
  );
};

ThinkDigitalGrid.propTypes = {
  gridImages: PropTypes.any,
  headingsProducts: PropTypes.any,
  productsDetails: PropTypes.any,
};

export default ThinkDigitalGrid;
