import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import divider from '../images/divider-colored.svg';

const MediaCard = props => (
  <a href={`${props.node.redirectionUrl}`} target='_blank'>
    <div
      className='card blog-card-mediamatch'
      style={{
        borderRadius: '10px',
        height: '100%',
      }}
    >
      {' '}
      <div className='card-image'>
        {props.node.featuredImage && (
          <div className='card-image-container'>
            <center>
              <img
                className='banks-grid-img'
                style={{
                  backgroundColor: props.node.color,
                  height: '20vh',
                  width: '90%',
                }}
                src={props.node.featuredImage.file.url}
              />
            </center>
          </div>
        )}
        <div className='card-content' style={{ height: '13vh' }}>
          <div className='media-content'>
            <p className='card-title is-4'> {props.node.shortDescription}</p>
          </div>
        </div>
      </div>
    </div>
  </a>
);

const MediaCardAll = () => (
  <Link to={`/media/`}>
    <div className='button'>
      <span className='button-text'>View All</span>
    </div>
  </Link>
);

const MediaLanding = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulMediaPost(sort: { fields: [priority], order: ASC }) {
        edges {
          node {
            redirectionUrl
            featuredImage {
              file {
                url
              }
            }
            publishedDate
            shortDescription
            priority
          }
        }
      }
    }
  `);
  return (
    <section className='column is-full'>
      <div>
        <center>
          <img src={divider} alt='divider' />
          <p className='SectionHeading' style={{ padding: 0, marginBottom: 1 }}>
            In the media
          </p>
        </center>
      </div>
      <div
        className='columns is-multiline blog-grid-card'
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {data &&
          data.allContentfulMediaPost.edges.slice(0, 4).map(edge => (
            <div
              className='column is-3-fullhd  is-3-desktop '
              style={{ marginTop: 60 }}
            >
              <MediaCard key={edge.node.id} node={edge.node} />
            </div>
          ))}
      </div>
      {data.allContentfulMediaPost.edges.length > 4 && (
        <div
          className='column'
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '40px',
          }}
        >
          <MediaCardAll />
        </div>
      )}
    </section>
  );
};

export default MediaLanding;
